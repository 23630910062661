<template>
  <page-layout>
    <span slot="header-name">{{ $tf('counters') }}</span>

    <el-button
      slot="header-actions"
      name="create-btn"
      class="header-button"
      circle
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ path: `/counters/create/` })"
      :disabled="$hasNoPermission(['ffsecurity.add_counter'])"
    />

    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>

    <div v-loading="state.loading" slot="content">
      <el-table
        name="counters-table"
        class="counters-table"
        stripe
        :size="$vars.sizes.table || $vars.sizes.common"
        :data="state.items"
        row-key="id"
        @row-click="rowClick"
      >
        <el-table-column prop="id" :label="$tf('id')" width="60">
          <template slot-scope="{ row }">
            <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.id }}</router-link>
          </template>
        </el-table-column>

        <el-table-column prop="image" :label="$tf('image')" width="160">
          <template slot-scope="{ row }">
            <counter-record-image :record="getLastCounterRecordsGroupItem(row.last)" />
          </template>
        </el-table-column>

        <el-table-column :label="$tf('counter_type')" width="160" align="center">
          <template slot-scope="{ row }">{{ computeLocalizedCounterType(row.multi_camera) }}</template>
        </el-table-column>

        <el-table-column :label="$tf('faces')" width="160" align="center">
          <template slot-scope="{ row }">
            {{ row.now_faces }}
          </template>
        </el-table-column>

        <el-table-column :label="$tf('silhouettes')" width="160" align="center">
          <template slot-scope="{ row }">
            {{ row.now_bodies }}
          </template>
        </el-table-column>

        <el-table-column width="180" :render-header="renderDistanceHeader">
          <template slot-scope="{ row }">
            <div v-if="!isEmpty(row.now_proximity_min)">
              {{ row.now_proximity_min.toFixed(2) + ' / ' + row.now_proximity_avg.toFixed(2) + ' / ' + row.now_proximity_max.toFixed(2) }}
            </div>
            <div v-else>- / - / -</div>
          </template>
        </el-table-column>

        <el-table-column :render-header="renderStateHeader">
          <template slot-scope="{ row }">
            <div class="status__item">
              <common-status :color="row.health_status.color" :colorDesc="row.health_status.code_desc"></common-status>
              <span v-if="row.health_status.statistic">
                <span style="white-space: nowrap">{{ row.health_status.statistic.failed_records }} / </span>
                <span style="white-space: nowrap">{{ row.health_status.statistic.total_records }}</span>
              </span>
              <span v-else>- / -</span>
            </div>
            <div class="status__item">
              <template v-if="getDistanceEnabled(row)">
                <el-tooltip effect="dark" :disabled="!row.proximity_calibration_error" :content="row.proximity_calibration_error" placement="bottom-start">
                  <span>{{ getTranslatedCalibrationStatus(row) }}</span>
                </el-tooltip>
              </template>
              <template v-else>{{ $tf('proximity_not_enabled') }}</template>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$tf('name')" prop="name" class-name="chart">
          <template slot-scope="{ row }">
            <div @click.stop>
              <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link>
            </div>
            <counter-chart-item :item="row" class="counter-table-chart" v-if="row.active" />
          </template>
        </el-table-column>

        <el-table-column prop="active" :label="$tf('active,,0')" width="120" align="center">
          <template slot-scope="{ row }">
            <div @click.stop>
              <el-checkbox v-model="row.active" :disabled="row.id < 0" @change="(v) => activeChange(v, row)"></el-checkbox>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import CounterRecordImage from './counter-record-image';
import CounterChartItem from './counter.chart.item';
import PageLayout from '@/components/page/layout';
import CommonStatus from '@/components/common/common-status';
import StatusColumnHeader from './status.column.header.vue';
import { CalibrationStatuses, CalibrationStatusesI18n } from './counters.constants';
import DistanceColumnHeader from '@/components/counters/distance.column.header';

export default {
  components: {
    CounterChartItem,
    TableNavigation,
    CounterRecordImage,
    PageLayout,
    CommonStatus
  },
  name: 'page-counters',
  computed: {
    model() {
      return 'counters';
    },
    state() {
      return this.$store.state.counters;
    },
    stats() {
      return this.$store.state.stats;
    }
  },
  data() {
    return {
      selection: [],
      updateInterval: ''
    };
  },
  methods: {
    isEmpty(v) {
      return v === null || v === undefined;
    },
    setUpdateInterval() {
      this.updateInterval = setInterval(() => {
        this.$store
          .dispatch(this.state.Action.Get)
          .then((v) => {
            this.items = v;
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          });
      }, 10000);
    },
    getDistanceEnabled(item) {
      return item.detect_proximity;
    },
    getCalibrationStatus(item) {
      return CalibrationStatuses[item.proximity_calibration_status];
    },
    getTranslatedCalibrationStatus(item) {
      const status = CalibrationStatusesI18n[this.getCalibrationStatus(item)];
      return this.$tf(status);
    },
    togglePlaying() {
      this.state.playing = !this.state.playing;
    },
    rowClick(item, cell) {
      const cellName = cell.property;
      if (cellName === 'image' || cellName === 'active' || cellName === 'name2') return;
      if (cellName === 'id') {
        this.$router.push({ path: this.getItemLink(item) });
      } else {
        this.$router.push({ path: this.getRecordsLink(item) });
      }
    },
    getCamera(item) {
      return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
    },
    getCameraGroup(item) {
      let camera = this.getCamera(item);
      return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || { name: '' };
    },
    deleteSelection() {},
    getItemLink(item) {
      let r = '/counters/' + encodeURIComponent(item.id) + '/';
      return r;
    },
    getRecordsLink(item) {
      let r = `/counter-records/filter/counter=${encodeURIComponent(item.id)}&limit=20`;
      return r;
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
    },
    getValue(v) {
      return v !== undefined && v !== '' ? v : '-';
    },
    getLastCounterRecordsGroupItem(group) {
      if (Array.isArray(group.records)) {
        return group.records[group.records.length - 1] ?? {};
      }
      return {};
    },
    computeLocalizedCounterType(multi_camera) {
      return this.$tf(multi_camera ? 'multi_camera' : 'single_camera');
    },
    renderStateHeader(h, { column }) {
      return h(StatusColumnHeader);
    },
    renderDistanceHeader(h, { column }) {
      return h(DistanceColumnHeader);
    }
  },
  mounted() {
    this.$store.dispatch('getAllCameras');
    this.setUpdateInterval();
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
};
</script>

<style lang="stylus">
.counters {
  justify-content: center;
}

.counter-table-chart {
  height: 150px;
}

.chart .cell {
  overflow: visible !important;
}

.status__item {
  margin 0 0 0.4rem;
}
</style>
