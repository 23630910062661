<template>
  <img :class="classes" v-if="record.thumbnail" :src="record.thumbnail" @click="openFullframeImageViewer" width="160" />
  <span v-else>-</span>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: { record: { type: Object, default: () => ({}) } }
})
export default class CounterRecordImage extends Vue {
  get classes() {
    return !this.record.fullframe ? 'counter-record-image' : '';
  }

  openFullframeImageViewer() {
    if (this.record.fullframe) {
      this.$store.dispatch('showImage', {
        src: this.record.fullframe,
        faces_bbox: this.normalizeRecordBBoxes(this.record.faces_bbox),
        silhouettes_bbox: this.normalizeRecordBBoxes(this.record.silhouettes_bbox)
      });
    }
  }

  normalizeRecordBBoxes(bboxes) {
    return bboxes?.map((bbox) => this.$filters.polyArrayToShortRect(bbox)) ?? [];
  }
}
</script>

<style lang="stylus">
.counter-record-image {
  cursor: default;
}
</style>
