var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('span',{attrs:{"slot":"header-name"},slot:"header-name"},[_vm._v(_vm._s(_vm.$tf('counters')))]),_c('el-button',{staticClass:"header-button",attrs:{"slot":"header-actions","name":"create-btn","circle":"","icon":"el-icon-plus","size":"small","disabled":_vm.$hasNoPermission(['ffsecurity.add_counter'])},on:{"click":function($event){return _vm.$router.push({ path: "/counters/create/" })}},slot:"header-actions"}),_c('table-navigation',{attrs:{"slot":"header-content-navigation","state":_vm.state},slot:"header-content-navigation"}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],attrs:{"slot":"content"},slot:"content"},[_c('el-table',{staticClass:"counters-table",attrs:{"name":"counters-table","stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items,"row-key":"id"},on:{"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('id'),"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"prop":"image","label":_vm.$tf('image'),"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('counter-record-image',{attrs:{"record":_vm.getLastCounterRecordsGroupItem(row.last)}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('counter_type'),"width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.computeLocalizedCounterType(row.multi_camera)))]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('faces'),"width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.now_faces)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('silhouettes'),"width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.now_bodies)+" ")]}}])}),_c('el-table-column',{attrs:{"width":"180","render-header":_vm.renderDistanceHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.isEmpty(row.now_proximity_min))?_c('div',[_vm._v(" "+_vm._s(row.now_proximity_min.toFixed(2) + ' / ' + row.now_proximity_avg.toFixed(2) + ' / ' + row.now_proximity_max.toFixed(2))+" ")]):_c('div',[_vm._v("- / - / -")])]}}])}),_c('el-table-column',{attrs:{"render-header":_vm.renderStateHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"status__item"},[_c('common-status',{attrs:{"color":row.health_status.color,"colorDesc":row.health_status.code_desc}}),(row.health_status.statistic)?_c('span',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.health_status.statistic.failed_records)+" / ")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.health_status.statistic.total_records))])]):_c('span',[_vm._v("- / -")])],1),_c('div',{staticClass:"status__item"},[(_vm.getDistanceEnabled(row))?[_c('el-tooltip',{attrs:{"effect":"dark","disabled":!row.proximity_calibration_error,"content":row.proximity_calibration_error,"placement":"bottom-start"}},[_c('span',[_vm._v(_vm._s(_vm.getTranslatedCalibrationStatus(row)))])])]:[_vm._v(_vm._s(_vm.$tf('proximity_not_enabled')))]],2)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('name'),"prop":"name","class-name":"chart"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_vm._v(_vm._s(row.name))])],1),(row.active)?_c('counter-chart-item',{staticClass:"counter-table-chart",attrs:{"item":row}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"active","label":_vm.$tf('active,,0'),"width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-checkbox',{attrs:{"disabled":row.id < 0},on:{"change":function (v) { return _vm.activeChange(v, row); }},model:{value:(row.active),callback:function ($$v) {_vm.$set(row, "active", $$v)},expression:"row.active"}})],1)]}}])})],1)],1),_c('table-navigation',{attrs:{"slot":"footer-content-navigation","state":_vm.state},slot:"footer-content-navigation"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }